<template>
    <div :style="style" v-if="todisplay" v-show="isvisible" :class="{ 'c-AdvancedHistory': true, [`c-name-${this.name || 'unnamed'}`]: true }">

        <v-snackbar multi-line :timeout="-1" :value="!!queryError" color="red">
            {{ queryError || 'This is a test of a large error message that you should see that might need to wrap it is very large.' }}

            <template v-slot:action="{ attrs }">
                <v-btn color="white"
                       text
                       v-bind="attrs"
                       @click="queryError = ''">
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <div style="display: flex; flex-direction: row; flex-grow: 1;">
            <div v-if="!colDefsLoaded" style="display: flex; flex-direction: column; flex-grow: 1; background-color: lightGrey; align-items: center; justify-content: center;">
                <v-progress-circular :size="70"
                                     :width="7"
                                     color="purple"
                                     indeterminate></v-progress-circular>
            </div>

            <div v-if="colDefsLoaded" style="display: flex; flex-direction: column; flex-grow: 1;">
                <!-- Display when not in dashboard -->
                <div style="display: flex; flex-direction: row;" class="pb-2" v-if="!nodata && !controlData.IsDashboard">
                    <c-Date-Range-Picker v-if="false && dateRangePickerVisible"
                                         style="min-width: 440px;" class="mb-1"
                                         type="daterangepicker"
                                         :allowTime="true"
                                         :timePickerIncrement="1"
                                         :isDisabled="false"
                                         :default="defaultTime"
                                         placeholder="Select Date Range"
                                         :allowFutureDate="true"
                                         :allowPastDate="true"
                                         :key="id"
                                         @apply-date-range="applyDateRangeHandler"
                                         @clear-date-range="clearDateRangeHandler"></c-Date-Range-Picker>

                    <MyCalendar v-if="dateRangePickerVisible"
                                :value="defaultTime"
                                width="430px"
                                label="Select Date Range"
                                :isactive="true"
                                activeicon="mdi-calendar"
                                @change="applyDateRangeHandler">
                    </MyCalendar>

                    <v-text-field dense clearable hide-details @click:clear="clearSearch" class="mb-1 ml-2 mt-4"
                                  style="flex-grow: 0; min-width: 280px; _margin-top: 4px; _margin-left: 10px;"
                                  placeholder="Quick Search"
                                  v-model="search"
                                  background-color="white">
                        <v-icon slot="prepend-inner">
                            mdi-magnify
                        </v-icon>
                    </v-text-field>

                    <v-btn v-if="ReadOnly && IsAnyViewFilterChoices" plain small :elevation="0" :color="tool_visible == 1 ? 'primary' : ''" class="mt-2 ml-2" @click="toggleDataFilters">
                        <v-badge color="primary" dot offset-y="25" offset-x="63" :value="IsAnyFilters">
                            <v-icon class="mr-1">mdi-filter</v-icon>Filters
                        </v-badge>
                    </v-btn>

                    <v-spacer></v-spacer>

                    <v-progress-circular v-if="false && query_in_progress" indeterminate color="primary" :size="20" class="mt-4 mr-5 ml-5"></v-progress-circular>

                    <span v-if="System.DEBUG" title="Total Rows" class="mt-5 pr-3 body-2" style="color: grey;">({{ totalHits }})</span>

                    <v-tooltip bottom>
                        <template v-slot:activator="{on, attrs}">
                            <v-btn small outlined class="mt-4 mr-4" color="grey darken-2" v-bind="attrs" v-on="on" @click="cycleDurationFormats">
                                {{ durationFormats[durationFormatIdx].text }}
                            </v-btn>
                        </template>
                        Duration Time Format<br />
                        Click to cycle through formats
                    </v-tooltip>

                    <!--<span class="mt-2" title="Query Elapsed Time">{{ query_elapsed }} </span>-->

                    <v-icon v-if="false && ReadOnly && IsAnyFilters" color="primary" title="Filter(s) Active">mdi-filter</v-icon>

                    <v-btn plain :elevation="0" _color="primary" class="mt-2 ml-0" @click="autoSizeAllColumns">
                        <v-icon title="Size columns to content">mdi-arrow-left-right-bold</v-icon>
                    </v-btn>

                    <v-btn plain :elevation="0" _color="primary" class="mt-2 ml-0" @click="sizeColumnsToFit">
                        <v-icon title="Size columns to fit">mdi-arrow-expand-horizontal</v-icon>
                    </v-btn>

                    <v-btn :disabled="query_in_progress" plain :elevation="0" class="mt-2 ml-0" @click="Refresh">
                        <v-icon title="Refresh" :class="{ 'icon-spinner': query_in_progress }">mdi-cached</v-icon>
                    </v-btn>

                    <ColumnMenu v-if="colDefsLoaded && ReadOnly"
                                :column_list="column_picker_list"
                                :column_order="column_state.columnOrder"
                                :columnDefs="columnDefs"
                                :column_default_visibility="column_default_visibility"
                                :setColumnsVisible="setColumnsVisible"
                                :debugMode="System.DEBUG"
                                :btnclass="{ 'mt-2': true }"
                                @showcolumnselector="showColumnSelector"
                                @showdatafilters="showDataFilters"></ColumnMenu>
                </div>
                <!-- Display when IN dashboard -->
                <div style="display: flex; flex-direction: column;" class="pb-2" v-if="!nodata && controlData.IsDashboard">
                    <c-Date-Range-Picker v-if="false"
                                         :style="{ visibility: dateRangePickerVisible ? 'initial' : 'hidden' }"
                                         style="max-width: 390px;" class="mb-1"
                                         type="daterangepicker"
                                         :allowTime="true"
                                         :timePickerIncrement="1"
                                         :isDisabled="false"
                                         :default="defaultTime"
                                         placeholder="Select Date Range"
                                         :allowFutureDate="true"
                                         :allowPastDate="true"
                                         :key="id"
                                         @apply-date-range="applyDateRangeHandler"
                                         @clear-date-range="clearDateRangeHandler"></c-Date-Range-Picker>

                    

                    <div style="display:flex;">
                        <div style="margin-top: 4px;">
                            <MyCalendar v-show="dateRangePickerVisible"
                                :key="id"
                                myclass="mb-1"
                                :value="defaultTime"
                                width="430px"
                                label="Select Date Range"
                                :isactive="true"
                                activeicon="mdi-calendar"
                                @change="applyDateRangeHandler">
                            </MyCalendar>
                        </div>
                        <v-text-field dense clearable hide-details @click:clear="clearSearch" class="mb-1 ml-2 mt-4"
                                      style="flex-grow: 0; min-width: 280px; _margin-top: 4px; _margin-left: 10px;"
                                      placeholder="Quick Search"
                                      v-model="search"
                                      background-color="white">
                            <v-icon slot="prepend-inner">
                                mdi-magnify
                            </v-icon>
                        </v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn v-if="ReadOnly" icon plain :elevation="0" class="mt-2 ml-0" :color="tool_visible == 1 ? 'primary' : 'secondary'" @click="toggleDataFilters">
                            <v-badge color="primary" dot offset-y="25" offset-x="11" :value="IsAnyFilters">
                                <v-icon class="mr-1">mdi-filter</v-icon>
                            </v-badge>
                        </v-btn>

                        <v-tooltip bottom>
                            <template v-slot:activator="{on, attrs}">
                                <v-btn small outlined class="mt-3" color="grey darken-2" v-bind="attrs" v-on="on" @click="cycleDurationFormats">
                                    {{ durationFormats[durationFormatIdx].text }}
                                </v-btn>
                            </template>
                            Duration Time Format<br />
                            Click to cycle through formats
                        </v-tooltip>

                        <v-btn plain :elevation="0" color="secondary" icon class="mt-2 ml-0" @click="autoSizeAllColumns">
                            <v-icon title="Size columns to content">mdi-arrow-left-right-bold</v-icon>
                        </v-btn>

                        <v-btn plain :elevation="0" color="secondary" icon class="mt-2 ml-0" @click="sizeColumnsToFit">
                            <v-icon title="Size columns to fit">mdi-arrow-expand-horizontal</v-icon>
                        </v-btn>

                        <v-btn :disabled="query_in_progress" plain icon color="secondary" :elevation="0" class="mt-2 ml-0" @click="Refresh">
                            <v-icon title="Refresh" :class="{ 'icon-spinner': query_in_progress }">mdi-cached</v-icon>
                        </v-btn>

                        <ColumnMenu v-if="colDefsLoaded && ReadOnly"
                                    :column_list="column_picker_list"
                                    :column_order="column_state.columnOrder"
                                    :columnDefs="columnDefs"
                                    :column_default_visibility="column_default_visibility"
                                    :setColumnsVisible="setColumnsVisible"
                                    :debugMode="System.DEBUG"
                                    :btnclass="{ 'mt-2': true, 'mr-2': false }"
                                    @showcolumnselector="showColumnSelector"
                                    @showdatafilters="showDataFilters">
                        </ColumnMenu>
                    </div>
                </div>

                <AgGridVue _key="unique_id"
                           v-if="colDefsLoaded" v-show="!nodata"
                           :class="{ 'ag-theme-alpine': true }"
                           :style="{ display: 'flex', flexDirection: 'column', flexGrow: '1' }"
                           :initialState="initialState"
                           :columnDefs="columnDefs"
                           :defaultColDef="defaultColDef"
                           :serverSideDatasource="dataSource"
                           :cacheBlockSize="pageSize"
                           :enableCellTextSelection="true"
                           :sideBar="sideBar"
                           :aggFuncs="aggFuncs"
                           _enableCharts="true"
                           :enableRangeSelection="true"
                           :columnTypes="dataTypeDefinitions"
                           :autoGroupColumnDef="autoGroupColumnDef"
                           :maintainColumnOrder="true"
                           :treeData="treeData"
                           :getRowClass="getRowClass"
                           _isServerSideGroup="isServerSideGroup"
                           _getServerSideGroupKey="getServerSideGroupKey"
                           _rowData="dataSource"
                           _datasource="dataSource"
                           rowModelType="serverSide"
                           serverSidePivotResultFieldSeparator="~"
                           @gridReady="onGridReady"
                           @stateUpdated="stateUpdated"
                           @firstDataRendered="onFirstDataRendered">

                </AgGridVue>


                <div v-if="colDefsLoaded && nodata"
                     style="flex-grow: 1; background-color: white; border: 1px solid silver; display: flex; flex-direction: column; align-content: center; align-items: center; justify-content: center;">
                    <div style="max-width: 280px; display: flex; flex-direction: column;" class="text-caption text-center">
                        <v-icon style="font-size: 80px;" class="mb-5">mdi-finance</v-icon>
                        Start building your report by selecting metrics from the panel on the right
                    </div>
                </div>
            </div>

            <div v-if="colDefsLoaded && !ReadOnly" v-show="tool_visible === 0"
                 class="c-ToolPanel_1 mx-auto w-100 pa-0 history-panel-width"
                 style="width: 100%; display: flex; flex-direction: column; _min-width: 650px; _max-width: 650px;">
                <div style="overflow: auto; width: 100%; display: flex; flex-direction: column; flex-basis: 0; flex-grow: 1; background-color: var(--v-extraLightGrey-base);" >
                    <v-expansion-panels hover accordion v-model="panelactive">
                        <v-expansion-panel v-if="!ReadOnly">
                            <v-expansion-panel-header class="text-h6">
                                Details
                                <template v-slot:actions>
                                    <v-icon color="primaryText" size="30px">
                                        mdi-menu-down
                                    </v-icon>
                                </template>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                                <v-card-subtitle class="mt-0 consistentText">
                                    Provide basic report information and setup
                                </v-card-subtitle>

                                <v-row dense class="ml-1">
                                    <v-col cols="12">
                                        <v-text-field background-color="white" dense outlined hide-details label="Report Name" v-model="report_name" @input="reportNameChanged" class="standard-input-field"></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row dense class="ml-1 mt-2">
                                    <v-col cols="12">
                                        <v-text-field background-color="white" dense outlined hide-details label="Description" v-model="report_description" @input="reportDescChanged" class="standard-input-field"></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row dense class="ml-1 mt-2">
                                    <v-col cols="12">
                                        <v-select dense outlined hide-details label="Source"
                                                  class="standard-input-field"
                                                  :menu-props="{ offsetY: true }"
                                                  :items="sourceTypes" v-model="source_selected"
                                                  item-text="DisplayName" item-value="SourceName"
                                                  @change="loadColumns(source_selected, true)"></v-select>
                                    </v-col>
                                </v-row>

                                <v-expansion-panels class="mt-3" v-model="advancedVisible">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header class="pl-3 consistentText" >
                                            Advanced settings
                                        </v-expansion-panel-header>

                                        <v-expansion-panel-content>
                                            <v-card-subtitle class="consistentText">Server-side pagination settings.</v-card-subtitle>
                                                <v-row no-gutters class="mt-3" align="center">
                                                    <v-col>
                                                        <v-text-field dense outlined
                                                                    label="Page Size"
                                                                    type="number"
                                                                    hide-details
                                                                    v-model="pageSize" :rules="pageSizeRules"
                                                                    @change="modelChanged"
                                                                    hint="Controls the number of rows to return before paginating"
                                                                    background-color="white">
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="1" class="pl-2">
                                                        <v-tooltip bottom open-delay="50">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon small v-bind="attrs" v-on="on" style="color:lightgrey">mdi-information-slab-circle</v-icon>
                                                            </template>
                                                            Controls how many rows are shown per page of the report. For example, if a report has 1000 rows and Normal Page Size is set to 100, the report will display 10 pages with 100 rows on each page.
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>

                            </v-expansion-panel-content>

                        </v-expansion-panel>

                        <v-expansion-panel>
                            <v-expansion-panel-header class="text-h6">
                                <template v-slot:actions v-if="ReadOnly">
                                    <v-icon title="Close" @click="tool_visible = undefined">mdi-close</v-icon>
                                </template>
                                <template v-else v-slot:actions>
                                    <v-icon color="primaryText" size="30px">
                                        mdi-menu-down
                                    </v-icon>
                                </template>
                                Columns
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                                <ToolRportColumns v-if="colDefsLoaded"
                                                  :key="unique_id"
                                                  :isvisible="true"
                                                  :ReadOnly="ReadOnly"
                                                  :settings="settings"
                                                  :column_list="column_picker_list"
                                                  :columnDefs="columnDefs"
                                                  :column_default_visibility="column_default_visibility"
                                                  :setColumnsVisible="setColumnsVisible"
                                                  :setColumnsDefaultHidden="setColumnsDefaultHidden"
                                                  :storageKey="storageKey"
                                                  :source="source_selected"
                                                  :debugMode="System.DEBUG">
                                </ToolRportColumns>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel>
                            <v-expansion-panel-header hide-actions class="text-h6">
                                <template v-slot:default="{ open }">
                                    Filters
                                    <!--<v-icon v-if="column_filters && Object.keys(column_filters).length > 0" color="primary">mdi-filter</v-icon>-->
                                    <v-card class="ma-0 pa-0 d-flex" flat color="transparent">
                                        <v-icon class="mr-auto expandIconSpoof" :class="{'rotate': open}" color="primaryText" size="30px" >
                                            mdi-menu-down
                                        </v-icon>
                                        <v-btn plain :elevation="0" color="primary" title="Clear All Filters" class="mr-3 v-btn--active"
                                            v-if="column_filters && Object.keys(column_filters).length > 0" :disabled="!open"
                                            @click.stop="clearAllFilters">
                                            Clear All
                                        </v-btn>
                                    </v-card>
                                </template>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                                <ToolReportFilters :key="unique_id"
                                                   :isvisible="true"
                                                   :ReadOnly="ReadOnly"
                                                   :settings="settings"
                                                   :column_list="column_filter_list"
                                                   :column_filters="column_filters"
                                                   :initial_column_filters="initial_column_filters"
                                                   :source="source_selected"
                                                   :EventBus="EventBus"
                                                   @filterschanged="notifyfilterschanged">
                                </ToolReportFilters>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
            </div>

            <div v-if="colDefsLoaded && ReadOnly && FilterModal">
                <v-dialog :value="tool_visible" :max-height="650" :max-width="600" scrollable persistent>
                    <v-card color="extraLightGrey"> 
                        <v-app-bar flat color="rgba(0, 0, 0, 0)" style="background-color: var(--v-navigation-base); height: 60px">
                            <v-toolbar-title class="title white--text pl-0">
                                <translation-container :context="this" value="Report Filters"></translation-container>
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <busyIcon></busyIcon>
                        </v-app-bar>

                        <v-card-text class="mt-2">
                            <div style="overflow: auto; width: 100%; display: flex; flex-direction: column; flex-basis: 0; flex-grow: 1;">
                                <ToolReportFilters :key="unique_id"
                                                   :isvisible="true"
                                                   :ReadOnly="ReadOnly"
                                                   :settings="settings"
                                                   :column_list="column_filter_list"
                                                   :column_filters="column_filters"
                                                   :initial_column_filters="initial_column_filters"
                                                   :source="source_selected"
                                                   :EventBus="EventBus"
                                                   @filterschanged="notifyfilterschanged">
                                </ToolReportFilters>
                            </div>
                        </v-card-text>

                        <v-card-actions class="ma-2" style="justify-content: flex-end;">
                            <v-btn elevation={0} color="primary" @click="tool_visible = undefined">
                                <translation-container :context="this" value="Done"></translation-container>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>

            <div v-if="colDefsLoaded && ReadOnly && !FilterModal" v-show="tool_visible === 1"
                 class="c-ToolPanel_1 mx-auto w-100 pa-0 history-panel-width"
                 style="width: 100%; display: flex; flex-direction: column; _min-width: 650px; _max-width: 650px;">
                <div style="overflow: auto; width: 100%; display: flex; flex-direction: column; flex-basis: 0; flex-grow: 1;">
                    <v-expansion-panels accordion mandatory focusable>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span class="text-h6">
                                    Filters
                                </span>

                                <template v-slot:actions>
                                    <v-btn plain :elevation="0" color="primary" title="Clear All Filters" class="mr-3 v-btn--active"
                                           v-if="ReadOnly && IsAnyFilters"
                                           @click.stop="clearAllFilters">Clear All</v-btn>
                                    <v-icon v-if="ReadOnly" title="Close" @click="tool_visible = undefined">mdi-close</v-icon>
                                </template>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content color="extraLightGrey">
                                <ToolReportFilters :key="unique_id"
                                                   :isvisible="true"
                                                   :ReadOnly="ReadOnly"
                                                   :settings="settings"
                                                   :column_list="column_filter_list"
                                                   :column_filters="column_filters"
                                                   :initial_column_filters="initial_column_filters"
                                                   :source="source_selected"
                                                   :EventBus="EventBus"
                                                   @filterschanged="notifyfilterschanged">
                                </ToolReportFilters>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
            </div>

            <v-card v-if="colDefsLoaded && tool_visible === 2" class="c-ToolPanel_1 mx-auto w-100 pa-0 history-panel-width" style="width: 100%; display: flex; flex-direction: column; _max-width: 650px;">
                <v-card class="mx-auto w-100 pa-0" style="overflow: auto; width: 100%; display: flex; flex-direction: column; flex-basis: 0; flex-grow: 1;" :elevation="0">
                    <v-card-title>
                        Report Settings JSON
                    </v-card-title>
                    <v-card-text>
<pre>
{{ JSON.stringify(ReportModel,null,3) }}
</pre>
                    </v-card-text>
                </v-card>
            </v-card>

            <v-btn-toggle v-if="colDefsLoaded && !ReadOnly" class="mr-n2" _manditory v-model="tool_visible" style="display: flex; flex-direction: column;">
                <v-btn color="extraLightGrey" large icon style="border: 1px solid; border-radius: 0px;">
                    <v-icon title="Column Selector" :color="tool_visible === 0 ? 'primary': 'secondary'">mdi-cogs</v-icon>
                </v-btn>
                <v-btn color="extraLightGrey" large icon v-show="false" style="border: 1px solid; border-radius: 0px;">
                    <v-icon title="Filters" :color="tool_visible == 1 ? 'primary': 'secondary'">{{ ReadOnly && IsAnyFilters ? 'mdi-filter' : 'mdi-filter-outline' }}</v-icon>
                </v-btn>
                <v-btn color="extraLightGrey" large icon v-if="System.DEBUG" style="border: 1px solid; border-radius: 0px;">
                    <v-icon title="Model" :color="tool_visible == 2 ? 'primary': 'secondary'">mdi-code-json</v-icon>
                </v-btn>
            </v-btn-toggle>
        </div>
        <IconComponent v-show="false" />

    </div>
</template>

<script>
    /* eslint-disable */

    import Vue from "vue";

    import utils from '../../Shared/utils.jsx';
    import helps from '../careHelpfulFunctions.jsx';
    import BaseComponent from './BaseComponentMixin.jsx';
    import AgContentDef from './AgContentDef.jsx';

    import _ from 'lodash';

    import 'ag-grid-enterprise';
    import { AgGridVue } from "ag-grid-vue";
    import cDateRangePicker from './vuecontrols/DateRangePicker.vue';
    import ToolRportColumns from './vuecontrols/advancedHistory/agGridToolReportColumns.vue';
    import ToolReportFilters from './vuecontrols/advancedHistory/agGridToolReportFilters.vue';
    import IconComponent from './vuecontrols/advancedHistory/iconComponent.vue';
    import ColumnMenu from './vuecontrols/advancedHistory/columnMenu.vue';
    import MyCalendar from './vuecontrols/calendar.vue';

    import common from './vuecontrols/advancedHistory/common.js';

    // If we move IconComponent into its own file, we can use .vue or .jsx and we
    // won't have to use the render function in this manner:
    const IconComponent0 = { // inline custom component for ag-grid
        methods: {
            sessionId() {
                return this.params && this.params.data ? JSON.stringify(this.params.data) : '...';
            },
            buttonClick(e) {
                alert('clicked ' + this.sessionId());
            },
        },
        render(h) {
            // h is a function that we call to create instances of any UI components.
            // Returning a button with an icon inside that can be clicked.
            const i = h('i',
                {
                    ['aria-hidden']: 'true',
                    class: 'v-icon notranslate mdi mdi-mdi mdi-magnify-plus-outline theme--light',
                    style: 'font-size: 20px;'
                }
            )
            const span = h('span',
                {
                    class: 'v-btn__content'
                },
                [i]
            );
            const button =
                h('button',
                    {
                        type: 'button',
                        class: 'v-btn v-btn--icon v-btn--round theme--light elevation-0 v-size--default secondary--text',
                        medium: 'true',
                        on: {
                            click: (e) => this.buttonClick(e)
                        }
                    },
                    [span]
                );

            return button;
        }
    };

    export default {
        name: "AdvancedHistory",
        mixins: [BaseComponent],
        components: {
            AgGridVue,
            AgContentDef,
            IconComponent,
            cDateRangePicker,
            ToolRportColumns,
            ToolReportFilters,
            ColumnMenu,
            MyCalendar,
        },
        data: function () {
            return {
                unique_id: utils.generateUUID(),
                id: 1,
                datespan: '12/01/2023 12:00:00 AM - 12/01/2023 11:59:59 PM',
                query_elapsed: '',
                query_result_size: 0,
                query_in_progress: false,
                query_refresh_pending: false,
                refreshOnReady: false,
                queryError: '',
                timezone: '',
                advancedVisible: false,
                delay_expn: 0,

                panelactive: 0,
                initialState: null,

                datamodel: null,
                report_model: null,
                defaultTime: 'today',
                baseurl: 'Apps/ReportStudio/AdvancedReporting/QueryDetail',
                //startDate : '2024-02-01T00:09:00.0000000Z',
                //endDate: '2024-03-01T00:09:00.0000000Z',
                search: '',
                active_source: 'contact_data_points3',
                active_interval: '1h',
                autoGroupColumnDef: null,
                colDefsLoaded: false,
                columnDefs: [ ],
                columnVisibility: {},
                customerLookup: {},
                customMetrics: null,

                filter_settings: {
                    datetime: {
                        filterType: 'number',
                        type: 'inRange',
                        filter: 'now/d',
                        filterTo: 'now',
                        usage: 1
                    }
                },
                column_state: {},
                column_default_visibility: {},

                // These settings are passed down the chain of components
                settings: {
                    startDate: '2024-02-01T00:09:00.0000000Z',
                    endDate: '2024-03-01T00:09:00.0000000Z',
                    pageSize: 100,
                    presortSize: 1000,
                    serverPaginationMode: 1,
                },

                tool_visible: 0,
                column_list: [],
                column_filters: {
                    datetime: {
                        filterType: 'number',
                        type: 'inRange',
                        filter: 'now/d',
                        filterTo: 'now',
                        usage: 1
                    }},
                initial_column_filters: null,

                viewonly_settings: {
                    column_picker_list: [],
                    column_filter_list: [],
                },

                durationFormatIdx: 0,
                durationFormats: [
                    { text: 'HH:MM:SS', value: 'HH:MM:SS' },
                    { text: 'Minutes', value: 'Minutes' },
                    { text: 'Seconds', value: 'Seconds' },
                ],

                source_selected: 'contacts',
                sourceTypes: [],

                pageSize: 100,
                pageSizeRules: [value => (value >= 10 && value <= 1000) || 'Between 10 - 1000 only'],

                defaultColDef: {
                    // set the default column width
                    width: 150,
                    // make every column editable
                    editable: false,
                    // make every column use 'text' filter by default
                    filter: 'agTextColumnFilter',
                    // enable floating filters by default
                    //floatingFilter: true,
                    // disable cell data types
                    //cellDataType: false,
                    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
                    defaultAggFunc: 'avg',
                },
                sideBar: {
                    toolPanels: [
                        {
                            id: 'columns',
                            labelDefault: 'Columns',
                            labelKey: 'columns',
                            iconKey: 'columns',
                            toolPanel: 'agColumnsToolPanel',
                            minWidth: 180,
                            maxWidth: 425,
                            width: 325
                        },
                        {
                            id: 'filters',
                            labelDefault: 'Filters',
                            labelKey: 'filters',
                            iconKey: 'filter',
                            toolPanel: 'agFiltersToolPanel',
                            minWidth: 180,
                            maxWidth: 425,
                            width: 325
                        }
                    ],
                    hiddenByDefault: true,
                    position: 'right',
                    defaultToolPanel: null
                },
                aggFuncs: {
                    cardinality: params => { return 0; },
                },
                dataTypeDefinitions: {
                    // override `date` to handle custom date format `dd/mm/yyyy`
                    dateColumn: {
                        filter: 'agDateColumnFilter',
                      //cellDataType: 'date',
                        resizable: true,
                        sortable: true,
                        keyCreator: params => {
                            return new Date(params.value).toISOString();
                        },
                        valueParser: params => {
                            if (params.newValue == null) {
                                return null;
                            }
                            // convert from ISO-8601
                            return new Date(params.newValue);
                        },
                        valueFormatter: params => {
                            // convert to locale date string
                            //debugger;
                            let options;
                            if (this.timezone)
                                options = { timeZone: this.timezone };

                            return (!params.value || params.value == '0001-01-01T00:00:00') ? ''
                                : (params.value == 'Never' || params.value == '9999-12-31T23:59:59.9999999') ? 'Never'
                                :
                                    new Date(params.value).toLocaleDateString(undefined, options) + ' ' +
                                    new Date(params.value).toLocaleTimeString(undefined, options);
                        },
                        suppressHeaderMenuButton: false,
                    },
                    dateOnlyColumn: {
                        filter: 'agDateColumnFilter',
                        //cellDataType: 'date',
                        resizable: true,
                        sortable: true,
                        keyCreator: params => {
                            return new Date(params.value).toISOString();
                        },
                        valueParser: params => {
                            if (params.newValue == null) {
                                return null;
                            }
                            // convert from ISO-8601
                            return new Date(params.newValue);
                        },
                        valueFormatter: params => {
                            // convert to locale date string
                            //debugger;
                            let options;
                            if (this.timezone)
                                options = { timeZone: this.timezone };

                            return (!params.value || params.value == '0001-01-01T00:00:00') ? ''
                                : (params.value == 'Never' || params.value == '9999-12-31T23:59:59.9999999') ? 'Never'
                                    :
                                    new Date(params.value).toLocaleDateString(undefined, options);
                        },
                        suppressHeaderMenuButton: false,
                    },
                    timeOnlyColumn: {
                        filter: 'agDateColumnFilter',
                        //cellDataType: 'date',
                        resizable: true,
                        sortable: true,
                        keyCreator: params => {
                            return new Date(params.value).toISOString();
                        },
                        valueParser: params => {
                            if (params.newValue == null) {
                                return null;
                            }
                            // convert from ISO-8601
                            return new Date(params.newValue);
                        },
                        valueFormatter: params => {
                            // convert to locale date string
                            //debugger;
                            let options;
                            if (this.timezone)
                                options = { timeZone: this.timezone };

                            return (!params.value || params.value == '0001-01-01T00:00:00') ? ''
                                : (params.value == 'Never' || params.value == '9999-12-31T23:59:59.9999999') ? 'Never'
                                    :
                                    new Date(params.value).toLocaleTimeString(undefined, options);
                        },
                        suppressHeaderMenuButton: false,
                    },
                    searchableTextColumn: {
                        filter: 'agTextColumnFilter',
                      //cellDataType: 'text',
                        resizable: true,
                        sortable: true,
                        filterParams: { debounceMs: 1000 },
                    },
                    directionColumn: {
                        sortable: true,
                        resizable: true,
                        enablePivot: true,
                        filter: 'agSetColumnFilter',
                        filterParams: { values: ['inbound', 'outbound'] },
                        cellClass: 'text-capitalize',
                    },
                    booleanColumn: {
                        sortable: true,
                        resizable: true,
                        cellDataType: 'boolean',
                        filter: 'agSetColumnFilter',
                        filterParams: { values: [ 'true', 'false' ] },
                        cellRenderer: 'agCheckboxCellRenderer',
                    },
                    textSetColumn: {
                        sortable: true,
                        resizable: true,
                        enablePivot: true,
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            refreshValuesOnOpen: true
                        }
                    },
                    channelTypeColumn: {
                        sortable: true,
                        resizable: true,
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            values: this.getUniqueColValues('timelinetype'),
                            refreshValuesOnOpen: true,
                            valueFormatter: params => {
                                if (params.value && params.value.substring(0, 14) == 'CustomChannel~')
                                    return params.value.substring(14);
                                else
                                    return params.value;
                            },
                            comparator: (x,y) => {
                                let a = x;
                                let b = y;
                                if (a.substring(0, 14) == 'CustomChannel~') a = a.substring(14);
                                if (b.substring(0, 14) == 'CustomChannel~') b = b.substring(14);
                                return a > b ? 1 : a < b ? -1 : 0;
                            }
                        },
                        valueFormatter: params => {
                            if (params.value && params.value.substring(0,14) == 'CustomChannel~')
                                return params.value.substring(14);
                            else
                                return params.value;
                        },
                    },
                    durationColumn: {
                        sortable: true,
                        resizable: true,
                        enableValue: true,
                        filter: 'agNumberColumnFilter',
                        cellClass: 'right-aligned-cell',
                        headerClass: 'ag-right-aligned-header',
                        valueFormatter: params => {
                            let seconds = params.value;
                            if (!(typeof seconds === 'number'))
                                return '';

                            let result = "";

                            switch (this.durationFormat) {
                                case 'HH:MM:SS':
                                    let neg = false;
                                    if (seconds < 0) {
                                        seconds = -seconds;
                                        neg = true;
                                    }
                                    seconds = Math.floor(seconds);

                                    let hours = Math.floor(seconds / (60 * 60));
                                    seconds = seconds - (hours * 60 * 60);

                                    let minutes = Math.floor(seconds / 60);
                                    seconds = seconds - (minutes * 60);

                                    if (hours)
                                        result = ((hours <= 9) ? "0" : "") + hours + ":" + ((minutes <= 9) ? "0" : "") + minutes + ":" + ((seconds <= 9) ? "0" : "") + seconds;
                                    else if (minutes)
                                        result = ((minutes <= 9) ? "0" : "") + minutes + ":" + ((seconds <= 9) ? "0" : "") + seconds;
                                    else if (seconds)
                                        result = "00:" + ((seconds <= 9) ? "0" : "") + seconds;
                                    else
                                        result = "00:00";

                                    if (neg) result = `-${result}`;
                                    break;

                                case 'Minutes':
                                    result = (seconds / 60).toFixed(2);
                                    break;

                                case 'Seconds':
                                default:
                                    result = seconds.toFixed(0);
                                    break;
                            }

                            return result;
                        }
                    },
                    countColumn: {
                        sortable: true,
                        resizable: true,
                        enableValue: true,
                        filter: 'agNumberColumnFilter',
                        cellClass: 'right-aligned-cell',
                        headerClass: 'ag-right-aligned-header',
                    },
                    floatColumn: {
                        sortable: true,
                        resizable: true,
                        enableValue: true,
                        filter: 'agNumberColumnFilter',
                        cellClass: 'right-aligned-cell',
                        headerClass: 'ag-right-aligned-header',
                        valueFormatter: params => {
                            if (typeof params.value === 'number')
                                return params.value.toFixed(1);
                            else
                                return '';
                        }
                    },
                    percentColumn: {
                        sortable: true,
                        resizable: true,
                        enableValue: true,
                        filter: 'agNumberColumnFilter',
                        cellClass: 'right-aligned-cell',
                        headerClass: 'ag-right-aligned-header',
                        valueFormatter: params => {
                            if (typeof params.value === 'number')
                                return (params.value * 100).toFixed(1) + '%';
                            else
                                return '';
                        }
                    },
                    customerIDColumn: {
                        sortable: true,
                        resizable: true,
                        valueFormatter: this.customerLookupFormatter,
                        enablePivot: true,
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            values: this.getUniqueColValues('ownerid'),
                            valueFormatter: this.customerLookupFormatter,
                            comparator: this.customerLookupComparator
                        }
                    },
                    userIDColumn: {
                        sortable: true,
                        resizable: true,
                        valueFormatter: this.userLookupFormatter,
                        enablePivot: true,
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            values: this.getUniqueColValues('userid'),
                            valueFormatter: this.userLookupFormatter,
                            comparator: this.userLookupComparator
                        }
                    },
                    currencyColumn: {
                        sortable: true,
                        resizable: true,
                        enableValue: true,
                        filter: 'agNumberColumnFilter',
                        cellClass: 'right-aligned-cell',
                        headerClass: 'ag-right-aligned-header',
                        valueFormatter: params => {
                            if (typeof params.value === 'number')
                                return this.f_currency(params.value);
                            else
                                return '';
                        }
                    },
                    phoneNumColumn: {
                        sortable: true,
                        resizable: true,
                        valueFormatter: this.phoneNumLookupFormatter,
                        enablePivot: true,
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            //values: this.getUniqueColValues('userid'),
                            valueFormatter: this.phoneNumLookupFormatter,
                            comparator: this.phoneNumLookupComparator
                        }
                    },
                    computedColumn: {
                        filter: 'agTextColumnFilter',
                        resizable: true,
                        sortable: true,
                    }
                },
                treeData: false, // true,
                groupColDef_byMasterID: {
                    filter: 'agGroupColumnFilter',
                    headerName: 'Start Date',
                    menuTabs: ['generalMenuTab'],
                    cellRendererParams: {
                      innerRenderer: (params) => {
                        return (!params.data.starttime || params.data.starttime == '0001-01-01T00:00:00') ? ''
                            : params.data.starttime == 'Never' ? 'Never'
                            :
                            new Date(params.data.starttime).toLocaleDateString() + ' ' + new Date(params.data.starttime).toLocaleTimeString();
                      },
                    },
                },
                groupColDef_Normal: {
                    filter: 'agGroupColumnFilter',
                },
                
                after_key: null, // stores the "next page" pagination key for aggregate data sets
                query_duration: 0,
                total_rows: 0,
                using_aggs: false,
                dataSource: {
                    getRows: async (server) => {
                        const params = server.request;
                        console.log(`params: ${JSON.stringify(params)}`);

                        // params: {"startRow":0,"endRow":100,"sortModel":[{"sort":"asc","colId":"starttime"}],"filterModel":{}}
                        // params: {"startRow":0,"endRow":100,"sortModel":[{"sort":"desc","colId":"prequeuetime"}],
                        //          "filterModel":{"callerid":{"filterType":"text","type":"contains","filter":"5432"}}}

                        const output = await this.queryFlatTable(params);
                        server.success(output);
                    }
                },

                report_name: '',
                report_description: '',
            }
        },
        created() {
            this.EventBus = new Vue();

            if ((this.controlData.DataType === 'URL' || this.controlData.DataType === undefined) && this.controlData.DataURL) {
                this.modelurl = utils.compile(this, this.controlData.DataURL);
            }
            else if (this.controlData.DataType === 'RawInterpolated' && this.controlData.DataRaw) {
                this.modelvalue = utils.compileObject(this, this.controlData.DataRaw);
            }

            if (this.ReadOnly)
                this.tool_visible = undefined;

            if (this.controlData.RefreshInterval)
                this.delay_expn = utils.compileExpression(this, this.controlData.RefreshInterval);

            if (this.controlData.Icons)
                this.precompileIconActions();

            if (this.controlData.ReportName)
                try {
                    let expn = utils.compile(this, this.controlData.ReportName);
                    this.report_name = utils.evaluate(expn, this);
                }
                catch (err) {
                    console.log(err.message);
                }

            if (this.controlData.ReportDescription)
                try {
                    let expn = utils.compile(this, this.controlData.ReportDescription);
                    this.report_description = utils.evaluate(expn, this);
                }
                catch (err) {
                    console.log(err.message);
                }

            if (this.controlData.PublishReportName) {
                let vueSet = utils.helpers.convertSetValueToVueSet(this.controlData.PublishReportName, 'value');
                this.setReportNameFunction = new Function('value', 'context', 'util', 'vue', `with (context) { ${vueSet} }`);
            }
            if (this.controlData.PublishReportDescription) {
                let vueSet = utils.helpers.convertSetValueToVueSet(this.controlData.PublishReportDescription, 'value');
                this.setReportDescriptionFunction = new Function('value', 'context', 'util', 'vue', `with (context) { ${vueSet} }`);
            }
        },
        //Created Replaced with preRenderComplete
        computed: {
            ReadOnly: function () {
                return !!this.controlData.ReadOnly;
            },
            FilterModal: function () {
                return !!this.controlData.FilterModal;
            },
            dateRangePickerVisible: function () {
                if (this.filter_settings && (this.filter_settings.starttime || this.filter_settings.datetime)) {
                    const f = this.filter_settings.starttime || this.filter_settings.datetime;
                    return f.usage > 0;
                }
                else
                    return true;
            },
            IsAnyFilters: function () {
                if (this.ReadOnly) {
                    if (!this.column_filters) return false;

                    // If any filters are present that have more than a usage flag, return true
                    return Object.keys(this.column_filters).some(a => {
                        const f = this.column_filters[a];
                        if (!f) return false;

                        if ('usage' in f)
                            return f.usage > 0 && Object.keys(f).length > 1;
                        else
                            return true;
                    });
                }
                else
                    return this.column_filters && Object.keys(this.column_filters).length > 0;
            },
            IsAnyViewFilterChoices: function () {
                // Returns true if there are any filters available to the user in view or dashboard mode, whether they are being used by user or not.
                // Used to show or hide filter icon if there are no filters to choose from.
                return this.column_filter_list.filter(a => a.ColId != 'datetime' && a.ColId != 'starttime').length > 0;
            },
            TimeZone: function () {
                if (!this.timezone_expn && this.controlData.TimeZone) {
                    this.timezone_expn = utils.compile(this, this.controlData.TimeZone);
                }

                if (this.timezone_expn)
                    return utils.evaluate(this.timezone_expn, this);
                else
                    return '';
            },
            Filters: function () {
                if (!this.filters_expn && this.controlData.Filters) {
                    this.filters_expn = utils.compile(this, this.controlData.Filters);
                }

                if (this.filters_expn)
                    return utils.evaluate(this.filters_expn, this);
                else
                    return '';
            },
            StartDate: function () {
                return this.settings.startDate;
            },
            EndDate: function () {
                return this.settings.endDate;
            },

            style() {
                return {
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column",
                    // minHeight: "400px",
                    padding: this.controlData.IsDashboard ? '0 8px' : '8px',
                    ...this.sizeStyle,
                };
            },
            storageKey: function () {
                // In view mode, the storage key is based on the report name (which can't change during view-only mode)
                if (this.ReadOnly)
                    return `detailedHistory-${this.report_name}`;
                else
                    return `historyGrid-${this.parentType}-${this.controlData.Name || 'unnamed'}-${this.controlData.$objectid || 'noid'}`;
            },
            modelurlvalue: function () {
                if (this.modelurl)
                    return utils.evaluate(this.modelurl, this);
                else
                    return '';
            },
            modelrawvalue: function () {
                if (this.modelvalue)
                    return utils.evaluateObject(this.modelvalue, this);
                else
                    return [];
            },

            _initialState() {
                //const rawstate = this.System.LocalStorage(this.storageKey + '-state');
                let stateobj = {};
                //if (rawstate)
                //{
                //    stateobj = JSON.parse(rawstate);
                //    //console.log('Restoring state: ' + rawstate);

                //    if (stateobj.columnVisibility) {
                //        this.columnVisibility = stateobj.columnVisibility;

                //        //gridApi.refreshToolPanel();
                //    }
                //}

                if (this.column_state && this.column_state.columnOrder) {
                    const invisiblecols = this.columnDefs.filter(a => a.colId && a.hide).map(a => a.colId);
                    stateobj.columnOrder = {
                        orderedColIds: this.column_state.columnOrder.concat(invisiblecols)
                    };
                    stateobj.columnVisibility = {
                        hiddenColIds: invisiblecols
                    };
                }
                console.log('initialState:');
                console.log(JSON.stringify(stateobj, null, 3));

                return stateobj;
            },
            toolColumnVisibility: function () {
                return this.columnVisibility;
            },
            toolColumnDefs: function () {
                return this.columnDefs;
            },
            nodata: function () {
                return false; // this.total_rows < 1;
            },
            totalHits: function () {
                return this.total_rows == 10000 ? '10,000+' : this.total_rows.toLocaleString();
            },

            durationFormat: function () {
                return this.durationFormats[this.durationFormatIdx].value;
            },

            column_picker_list: function () {
                if (this.ReadOnly)
                    return this.viewonly_settings.column_picker_list;
                else
                    return this.column_list;
            },
            column_filter_list: function () {
                if (this.ReadOnly)
                    return this.viewonly_settings.column_filter_list;
                else
                    return this.column_list;
            },

            ReportModel: function () {
                let columns;
                if (this.column_state.columnOrder) {
                    // Grab the columns from the state order list
                    columns = this.column_state.columnOrder.filter(a => this.columnDefs.some(b => b.colId == a && !b.hide)).map(c => ({ colId: c }));

                    // If any columns are visible that aren't in the state order list, add them
                    for (let c of this.columnDefs.filter(a => a.colId && !a.hide)) {
                        if (!columns.some(a => a.colId == c.colId))
                            columns.push({ colId: c.colId });
                    }
                }
                else
                    columns = this.columnDefs.filter(a => a.colId && !a.hide).map(b => ({ colId: b.colId }));

                if (this.column_default_visibility)
                    for (let colid of Object.keys(this.column_default_visibility)) {
                        const c = columns.find(a => a.colId == colid);
                        if (c) c.defaulthidden = true;
                    }

                if (this.column_state.columnSizing)
                    for (let z of this.column_state.columnSizing) {
                        const c = columns.find(a => a.colId == z.colId);
                        if (c) c.width = z.width;
                    }

                if (this.column_state.leftPinned)
                    for (let z of this.column_state.leftPinned) {
                        const c = columns.find(a => a.colId == z);
                        if (c) c.pinLeft = true;
                    }

                if (this.column_state.rightPinned)
                    for (let z of this.column_state.rightPinned) {
                        const c = columns.find(a => a.colId == z);
                        if (c) c.pinRight = true;
                    }

                const colmods = [...new Set(columns.map(a => a.colId))].map(a => common.column_lookup[a]).flatMap(a => a && a.Dependencies ? a.Dependencies.Modules : []);
                const modules = [...new Set(colmods)];

                return {
                    source: this.source_selected,
                    filters: this.filter_settings || {},
                    columns: columns,
                    dependencies: {
                        modules: modules
                    },
                };
            },
        },
        async mounted() {
            let hasSetTime = false;

            //const filters = this.QueryString('report_filters') || this.Filters;
            //if (filters) {
            //    const query_filters = JSON.parse(filters);
            //    for (let key of Object.keys(query_filters)) {
            //        if (key == 'starttime') {
            //            const filter = query_filters[key];

            //            this.settings.startDate = filter.filter;
            //            this.settings.endDate = filter.filterTo;

            //            // Update date range picker
            //            this.defaultTime = JSON.stringify({ startDate: this.settings.startDate, endDate: this.settings.endDate });
            //            // this.id++; // cause date range picker to reload
            //            hasSetTime = true;

            //            console.log(`-- mounted [${key}] filter:${JSON.stringify(filter)}`);
            //        }

            //        console.log(`-- mounted [${key}] defaultTime: ${this.defaultTime} - filters:${filters}`);
            //    }
            //}
            if (!hasSetTime) {
                //const oldDateRange = this.System.LocalStorage(this.storageKey + '-daterange');
                //if (oldDateRange) {
                //    //this.dateRange = oldDateRange;
                //    this.settings.startDate = oldDateRange.split(' - ')[0];
                //    this.settings.endDate = oldDateRange.split(' - ')[1];

                //    this.defaultTime = JSON.stringify({ startDate: this.settings.startDate, endDate: this.settings.endDate });
                //}
                //else {
                    this.settings.startDate = common.getRelativeDateFromFilter('now/d');
                    this.settings.endDate = common.getRelativeDateFromFilter('now', true);

                    this.defaultTime = JSON.stringify({ startDate: this.settings.startDate, endDate: this.settings.endDate });
                //}

                utils.log(`-- mounted Loading localStorage(${this.storageKey}-daterange): ${this.settings.startDate} - ${this.settings.endDate}`);
            }

            this.autoGroupColumnDef = this.groupColDef_byMasterID;
        },
        methods: {
            async preRenderComplete() {
                this.finishRenderHandler(this);

                // Look for a form_changed_indicator up the parent hierarchy
                let p = this.$parent;
                while (p && (!('cc_form_changed_indicator' in p) || !p.cc_form_changed_active))
                    p = p.$parent;

                if (p) {
                    p.cc_form_changed_linked = true;
                    p.cc_form_changed_form = this;
                    this.notify_object = p;
                }

                await common.loadLookupTables(this._uid, this.ReadOnly);

                this.sourceTypes = common.sourceTypesList;

                await this.loadReportModel();

                if (this.report_model && this.report_model.source)
                    this.source_selected = this.report_model.source;

                this.loadColumns(this.source_selected);

                if (this.report_model)
                    this.applyReportModel();

                this.colDefsLoaded = true;

                const delay = this.delay_expn ? parseInt(utils.evaluate(this.delay_expn, this)) || 0 : 0;
                if (delay)
                    this.interval = setInterval(() => {
                        this.Refresh();
                    }, delay * 60 * 1000);

                //this.Refresh();
            },
            async loadReportModel() {
                console.log('loadReportModel(); controlData:');
                console.log(JSON.stringify(this.controlData));

                let model;
                if (this.controlData.DataType == 'URL' && this.modelurl) {
                    try {
                        model = this.modelurlvalue ? await utils.api.get(this.modelurlvalue, false, false) : [];
                    }
                    catch (e) {

                        // SignalR backwards compatability
                        var statusCode = e.StatusCode ? e.StatusCode : e.statusCode;
                        var reasonPhrase = e.ReasonPhrase ? e.ReasonPhrase : e.reasonPhrase;

                        let msg = `Failed in API call to ${this.modelurlvalue}`;
                        utils.warn(`${this.type} ${this.name} ${msg}`, e);
                        msg = `API call failed: ${reasonPhrase}`;
                        this.EventBus.$emit('v-toast', {
                            text: 'Error',
                            subtext: e ? msg : 'Unknown error',
                            type: 'error',
                            icon: 'mdi mdi-alert',
                            ontap: [
                                {
                                    "PrimitiveAction": true,
                                    "ActionType": "DisplayConfirmModal",
                                    "RunAsync": false,
                                    "ActionData": {
                                        "OkayButtonText": "Okay",
                                        "CancelButtonText": "Cancel",
                                        "Debug": {},
                                        "Title": "Error " + (e ? statusCode : ''),
                                        "Message": `Failed in API call to ${this.modelurlvalue} - ${reasonPhrase}`
                                    }
                                }
                            ],
                            context: this,
                        });
                    }
                }
                else if (this.controlData.DataType == 'RawInterpolated' && this.modelvalue)
                    try {
                        model = this.modelrawvalue;

                        console.log(`model: ${JSON.stringify(model)}`);
                    }
                    catch (e) {
                        utils.warn('BasicGrid modelvalue ' + this.controlData.Data + ' failed to evaluate: ' + e);
                        model = {};
                    }
                else if (this.controlData.DataType == 'Raw' && this.controlData.Data)
                    model = this.controlData.Data;

                this.report_model = model;
            },
            applyReportModel() {
                // First filter out any filters or columns that do not exist (may have been deleted)
                if (this.report_model.filters)
                    this.report_model.filters = helps.toLookup(Object.keys(this.report_model.filters).filter(a => a in common.column_lookup).map(a => ({ colId: a, value: this.report_model.filters[a] })), 'colId', 'value');
                if (this.report_model.columns)
                    this.report_model.columns = this.report_model.columns.filter(a => a.colId in common.column_lookup);

                if (this.report_model.source)
                    this.source_selected = this.report_model.source;

                if (this.report_model.filters) {
                    this.filter_settings = this.report_model.filters;
                    this.column_filters = { ...this.report_model.filters }; // Used only to pre-initialize filters pane
                }

                if (this.report_model.columns) {
                    // Make visible all columns listed in the report definition
                    for (let c of this.report_model.columns) {
                        const cdef = this.columnDefs.find(a => a.colId == c.colId);
                        if (cdef) {
                            cdef.hide = false;

                            if (c.width)
                                cdef.width = c.width;
                            if (c.pinLeft)
                                cdef.pinned = 'left';
                            else if (c.pinRight)
                                cdef.pinned = 'right';

                            if (c.defaulthidden)
                                this.column_default_visibility[c.colId] = 1;
                        }
                        else
                            console.log(`Failed to locate col:${c.colId}`);
                    }

                    const colvisjson = this.System.LocalStorage(this.storageKey + '-columnsvisible');
                    let colhide;
                    if (colvisjson) {
                        colhide = JSON.parse(colvisjson).filter(a => !a.visible).map(a => a.colId);

                        this.column_default_visibility = {};
                        for (let colid of colhide)
                            this.column_default_visibility[colid] = 1;
                    }

                    this.column_state = {
                        columnOrder: this.report_model.columns.map(a => a.colId),
                        columnSizing: this.report_model.columns.filter(a => a.width).map(a => ({ colId: a.colId, width: a.width })),
                        leftPinned: this.report_model.columns.filter(a => a.pinLeft).map(a => a.colId),
                        rightPinned: this.report_model.columns.filter(a => a.pinRight).map(a => a.colId),
                    };

                    let stateobj = {};

                    if (this.ReadOnly) {
                        this.viewonly_settings = {
                            column_picker_list: this.column_list.filter(a => this.column_state.columnOrder.includes(a.ColId)),
                            column_filter_list: this.column_list.filter(a => this.report_model.filters[a.ColId] && this.report_model.filters[a.ColId].usage),
                        };

                        if (this.column_state && this.column_state.columnOrder) {
                            const invisiblecols = this.columnDefs.filter(a => a.colId && a.hide).map(a => a.colId);
                            let hiddencols;
                            if (colhide)
                                hiddencols = this.columnDefs.filter(a => a.colId && a.hide || colhide.includes(a.colId)).map(a => a.colId);
                            else
                                hiddencols = this.columnDefs.filter(a => a.colId && (a.hide || this.column_default_visibility[a.colId])).map(a => a.colId);

                            stateobj.columnOrder = {
                                orderedColIds: this.column_state.columnOrder.concat(invisiblecols)
                            };
                            stateobj.columnVisibility = {
                                hiddenColIds: hiddencols
                            };
                        }

                        for (let col of this.columnDefs)
                            if (this.column_default_visibility[col.colId])
                                col.hide = true;

                        this.initial_column_filters = _.cloneDeep(this.report_model.filters); // Used in ReadOnly to provide the base filter (for limited filtering, usage:2)

                        const filters = this.QueryString('report_filters') || this.Filters;
                        if (filters) {
                            const query_filters = JSON.parse(filters);
                            for (let key of Object.keys(query_filters)) {
                                //if (key in this.initial_column_filters && this.initial_column_filters[key].usage)
                                {
                                    // Only allow filters that have been allowed
                                    const filter = query_filters[key];

                                    // Merge together the default filter with the query string filter
                                    //this.initial_column_filters[key] = { ...this.initial_column_filters[key], ...filter };
                                    this.report_model.filters[key] = { ...this.report_model.filters[key], ...filter };
                                    this.column_filters[key] = { ...this.column_filters[key], ...filter };

                                    //if (key == 'starttime') {
                                    //    this.settings.startDate = filter.filter;
                                    //    this.settings.endDate = filter.filterTo;

                                    //    // Update date range picker
                                    //    this.defaultTime = JSON.stringify({ startDate: this.settings.startDate, endDate: this.settings.endDate });
                                    //    this.id++; // cause date range picker to reload
                                    //}

                                    console.log(`-- applyReportModel this.column_filters[${key}]: ${JSON.stringify(this.column_filters[key], null, 3)}`);
                                }
                            }
                        }

                        if ('starttime' in this.column_filters || 'datetime' in this.column_filters) {
                            const filter = this.column_filters.starttime || this.column_filters.datetime;
                            this.settings.startDate = common.getRelativeDateFromFilter(filter.filter);
                            this.settings.endDate = common.getRelativeDateFromFilter(filter.filterTo, true);
                            this.defaultTime = JSON.stringify({ startDate: this.settings.startDate, endDate: this.settings.endDate });

                            console.log(`-- applyReportModel this.column_filters.starttime: ${JSON.stringify(filter, null, 3)}`);
                            console.log(`-- applyReportModel defaultTime: ${this.defaultTime}`);
                        }
                    }
                    else {
                        if (this.column_state && this.column_state.columnOrder) {
                            const invisiblecols = this.columnDefs.filter(a => a.colId && a.hide).map(a => a.colId);
                            stateobj.columnOrder = {
                                orderedColIds: this.column_state.columnOrder.concat(invisiblecols)
                            };
                            stateobj.columnVisibility = {
                                hiddenColIds: invisiblecols
                            };
                        }
                    }
                    console.log('setting initialState:');
                    console.log(JSON.stringify(stateobj));

                    this.initialState = stateobj;

                    console.log('Initial Column Defs:');
                    console.log(JSON.stringify(this.columnDefs.filter(a => a.colId && !a.hide), null, 3));

                    console.log('column_state.columnOrder:');
                    console.log(JSON.stringify(this.column_state.columnOrder));
                }

                if (this.report_settings)
                    this.applyTableReport(this.report_settings);
                else if (this.gridApi)
                    this.Refresh();
                else
                    this.refreshOnReady = true;
            },
            modelChanged() {
                // If the form model changes set the cc_form_changed_indicator
                if (this.notify_object) {
                    utils.warn(`AdvancedHistory ${this.name} change fired, notifying ${this.notify_object ? this.notify_object.name : 'null'}`);
                    //utils.warn(JSON.stringify(newvalue));

                    this.notify_object.formChanged();
                }
            },
            async Refresh() {
                if (this.query_in_progress) {
                    this.query_refresh_pending = true;
                    return;
                }

                if (this.gridApi && this.settings.startDate && this.settings.endDate) {
                    this.after_key = null;
                    this.gridApi.refreshServerSide();
                }
            },
            async precompileIconActions() {
                // Compile the actions for each Icon to allow interpolation within each row efficiently
                for (let item of this.controlData.Icons) {
                    if (item.DisplayExpression)
                        item.displayExpn = utils.compileExpression(this, item.DisplayExpression, 'row');
                    if (item.VisibleExpression)
                        item.visibleExpn = utils.compileExpression(this, item.VisibleExpression, 'row');
                    if (item.EnabledExpression)
                        item.enabledExpn = utils.compileExpression(this, item.EnabledExpression, 'row');

                    if (item.Actions)
                        for (let act of item.Actions) {
                            await utils.compileAction(this, act);
                        }

                    if (item.MenuItems)
                        for (let m of item.MenuItems) {
                            if (m.Title)
                                m.titleExpn = utils.compile(this, m.Title, null, 'row');
                            if (m.Tooltip)
                                m.tooltipExpn = utils.compile(this, m.Tooltip, null, 'row');
                            if (m.Icon)
                                m.iconExpn = utils.compile(this, m.Icon, null, 'row');

                            if (m.VisibleExpression)
                                m.visibleExpn = utils.compileExpression(this, m.VisibleExpression, 'row');
                            if (m.EnabledExpression)
                                m.enabledExpn = utils.compileExpression(this, m.EnabledExpression, 'row');

                            if (m.Actions)
                                for (let act of m.Actions) {
                                    await utils.compileAction(this, act);
                                }
                        }
                }
            },

            async loadSourceTypes() {
                const res = await utils.api.get('Apps/Platform/Schema/AdvancedReports/v1/ReportingSources');
                try {
                    return res.SourceTypes.toSorted((a, b) => common.byField(a, b, 'DisplayName'));
                }
                catch (ex) {
                    console.error(ex.message);
                    return [];
                }
            },
            async loadCustomerList() {
                if (this.GlobalVars && this.GlobalVars.AllCustomers)
                    return common.remaplookup(this.GlobalVars.AllCustomers, 'Name');
                else {
                    const res = await utils.api.get('/Apps/ReportStudio/Utils/GetMyCustomerList');
                    return common.transformer(res, 'Name', 'CustomerID');
                }
            },
            async loadUserList() {
                if (this.GlobalVars && this.GlobalVars.AllUsers)
                    return common.remaplookup(this.GlobalVars.AllUsers, 'FullName');
                else if (this.GlobalVars && this.GlobalVars.Users)
                    return common.remaplookup(this.GlobalVars.Users, 'fullname');
                else {
                    const res = await utils.api.get('/Apps/ReportStudio/Utils/GetMyUserList');
                    return common.transformer(res, 'FullName', 'UserID');
                }
            },
            async loadChatTouchpoints() {
                const res = await utils.api.get('/Apps/Common/ChatTouchPoints');
                return common.transformer(res, 'Name', 'TouchPointID');
            },
            loadColumns(source, reassign) {
                this.column_list = common.column_list.filter(a => a.Sources.includes(source));

                const visible = this.columnDefs.filter(a => a.colId && !a.hide).map(a => a.colId);

                this.columnDefs = [
                    {
                        //checkboxSelection: true,
                        //showDisabledCheckboxes: true,
                        lockPosition: 'left',
                        pinned: 'left',
                        sortable: false,
                        suppressMovable: true,
                        filter: false,
                        suppressHeaderMenuButton: true,
                        cellRenderer: 'IconComponent',
                        cellRendererParams: {
                            customdata: {
                                root: this.root,
                                scopeitems: this.scopeitems,
                                controlscope: this.controlscope,
                                context: this,
                                Icons: this.controlData.Icons,
                            }
                        },
                    },
                    ...common.getColumnDefs(source)
                ];

                for (let cdef of this.columnDefs)
                    if (visible.includes(cdef.colId))
                        cdef.hide = false;

                if (this.gridApi && reassign)
                    this.gridApi.setGridOption('columnDefs', this.columnDefs);

                if (this.ReadOnly)
                    this.viewonly_settings = {
                        column_picker_list: [],
                        column_filter_list: [],
                    };

                this.column_default_visibility = {};
                if (this.columnDefs.some(a => a.colId == 'datetime'))
                    this.filter_settings = {
                        datetime: {
                            filterType: 'number',
                            type: 'inRange',
                            filter: 'now/d',
                            filterTo: 'now',
                            usage: 1
                        }
                    };
                else
                    this.filter_settings = {};

                this.column_filters = { ...this.filter_settings };

                // Force components with the unique_id as the key to reload
                if (reassign) {
                    this.unique_id = utils.generateUUID();
                    this.Refresh();
                }
            },
            async loadColumns_old(source, reassign) {
                // Grab the sets of columns by usage
                const columns = await utils.api.get('Apps/Platform/Schema/AdvancedReports/v1/Column/ListByID?OnlyMine=true');
                common.column_lookup = helps.toLookup(columns, 'ColId');

                this.column_list = columns.filter(a => a.Sources.includes(source));

                console.log(`loadColumns(source:${source} returned ${this.column_list.length} column(s)`);

                try {
                    const tasks = [];
                    for (let c of this.column_list) {
                        if (c.MappingTableType == 'LookupAPI') {
                            tasks.push(common.loadLookup(c));
                        }
                        else if (c.MappingTableType == 'LookupList') {
                            c.lookups = helps.toLookup(c.MappingTable, 'Key', 'Value');
                        }
                    }
                    await Promise.all(tasks);

                    console.log(`loadColumns completed lookups for ${tasks.length} task(s)`);
                }
                catch (err) {
                    console.error(`loadColumns - loading lookups failed: ${err.message || err.reasonPhrase}`);
                }

                // Generate the list of column definitions for ag-grid
                let cols = this.column_list.filter(a => a.SourceType).map(a => ({
                    colId: a.ColId,
                    field: a.ColumnUse.Computed ? a.ColId : a.SourceField.replace('.keyword',''), // Strip any fields with the .keyword suffix as this will not match the column in the result set
                    headerName: a.Title,
                    type: a.SourceType,
                    hide: true,
                    menuTabs: ['generalMenuTab'],
                })).toSorted((a, b) => common.byField(a, b, 'headerName'));

                for (let i = 0; i < cols.length; i++) {
                    const row = cols[i];
                    if (row.type == 'textSetColumn' || row.type == 'phoneNumColumn') {
                        row.filterParams = {
                            values: this.getUniqueColValues(row.colId),
                            refreshValuesOnOpen: true
                        };
                        const c = common.column_lookup[row.colId];
                        if (c && c.lookups) {
                            row.valueFormatter = (v) => {
                                if (typeof v.value === 'object' && Array.isArray(v.value))
                                    return v.value.map(a => c.lookups[a] || a).join(', ');
                                else
                                    return c.lookups[v.value] || v.value;
                            }
                        }
                    }
                }

                this.columnDefs = [
                    {
                        //checkboxSelection: true,
                        //showDisabledCheckboxes: true,
                        lockPosition: 'left',
                        pinned: 'left',
                        sortable: false,
                        suppressMovable: true,
                        filter: false,
                        suppressHeaderMenuButton: true,
                        cellRenderer: 'IconComponent',
                        cellRendererParams: {
                            customdata: {
                                root: this.root,
                                scopeitems: this.scopeitems,
                                controlscope: this.controlscope,
                                context: this,
                                Icons: this.controlData.Icons,
                            }
                        },
                    },
                    ...cols
                ];

                console.log(`loadColumns - assigned ${this.columnDefs.length} column def(s)`);

                if (this.gridApi && reassign)
                    this.gridApi.setGridOption('columnDefs', this.columnDefs);

                // Force components with the unique_id as the key to reload
                if (reassign) {
                    console.log(`loadColumns reassigning unique_id: ${this.unique_id} (_uid:${this._uid})`);

                    this.unique_id = utils.generateUUID();

                    console.log(`loadColumns reassigned unique_id: ${this.unique_id} (_uid:${this._uid})`);
                }
            },
            async loadLookupTables() {
                if (this.report_model && this.report_model.source)
                    this.source_selected = this.report_model.source;

                const [sourcetypes, customerlist, userlist, chatlist, _] = await Promise.all([
                    this.loadSourceTypes(),
                    this.loadCustomerList(),
                    this.loadUserList(),
                    this.loadChatTouchpoints(),
                    this.loadColumns(this.source_selected)
                ]);

                common.sourceTypes = helps.toLookup(sourcetypes, 'SourceName', 'DisplayName');
                this.sourceTypes = sourcetypes;
                common.customerLookup = customerlist;
                common.userLookup = userlist;
                common.chatLookup = chatlist;
            },

            notifyfilterschanged(filters, colId) {
                console.log('notifyfilterschanged:');
                console.log(JSON.stringify(filters, null, 3));

                if ((colId == 'starttime' || colId == 'datetime') && colId in filters) {
                    const f = filters[colId];
                    if (f.usage > 0) {
                        this.settings.startDate = common.getRelativeDateFromFilter(f.filter);
                        this.settings.endDate = common.getRelativeDateFromFilter(f.filterTo, true);
                        this.defaultTime = JSON.stringify({ startDate: this.settings.startDate, endDate: this.settings.endDate });
                    }
                }

                this.filter_settings = filters;
                this.Refresh();

                this.modelChanged();
            },

            async queryFlatTable(params) {
                // When using rowGroup:
                // {"startRow":0,"endRow":100,"rowGroupCols":[{"id":"direction","displayName":"Direction","field":"direction"}],"valueCols":[],"pivotCols":[],"pivotMode":false,"groupKeys":["inbound"],"filterModel":{},"sortModel":[]}
                const q_from = this.using_aggs ? 0 : params.startRow;
                const q_size = this.using_aggs ? this.pageSize : (params.endRow - params.startRow);
                let dataurl = `${common.buildRequest(this.baseurl, this.settings.startDate, this.settings.endDate)}&PageSize=${q_size}&From=${q_from}&Source=${this.source_selected}`;

                if (this.columnDefs && this.columnDefs.length > 0) {
                    const cols = this.columnDefs.filter(a => a.colId && !a.hide).map(a => a.colId);
                    if (cols.length < 1) {
                        console.log('No columns selected');
                        return {
                            rowData: [],
                            rowCount: 0,
                        };
                    }

                    // The drill-down ability depends on these columns, so even though we might not be displaying them, we must request them.
                    if (!cols.includes('timelineid'))
                        cols.push('timelineid');
                    if (!cols.includes('timelinetype'))
                        cols.push('timelinetype');
                    if (!cols.includes('recordtype'))
                        cols.push('recordtype');
                    if (!cols.includes('queuename'))
                        cols.push('queuename');
                    if (!cols.includes('recorded'))
                        cols.push('recorded');
                    if (!cols.includes('recordingexpire'))
                        cols.push('recordingexpire');
                    // This isn't very maintainable to have to include destination this way, but the drill-down for call CDR depends on it...
                    if (this.source_selected == 'contacts' && !cols.includes('destination'))
                        cols.push('destination');

                    dataurl += `&Columns=${encodeURIComponent(JSON.stringify(cols))}`;
                }

                if (params.sortModel && params.sortModel.length) {
                    dataurl += `&sortModel=${JSON.stringify(params.sortModel)}`;
                }

                if (params.filterModel) {
                    // Mix the ag-grid filter settings with the newer custom filter settings from agGridToolReportFilters.vue
                    const f = { ...this.filter_settings, ...params.filterModel };
                    dataurl += '&filters=' + encodeURIComponent(JSON.stringify(f));
                    console.log('filterModel: ' + JSON.stringify(f));
                }

                if (this.search)
                    dataurl += '&search=' + encodeURIComponent(this.search);

                if (this.TimeZone)
                    dataurl += '&WindowsTimeZone=' + encodeURIComponent(this.TimeZone);

                if (this.report_name)
                    dataurl += '&referenceid=' + encodeURIComponent(this.report_name);

                dataurl += '&UseStartEnd=true';

                // If we want to override the call center's time zone, we can use this to get our browser's time zone:
                //  Intl.DateTimeFormat().resolvedOptions().timeZone

                console.log('url: ' + dataurl.split('&').join('\n'));

                this.query_in_progress = true;
                try {
                    const res = await utils.api.get(dataurl);

                    console.log(`get returned ${res && res.rows ? res.rows.length : 0} row(s)`);
                    console.log(JSON.stringify(res.rows.slice(0,3), null, 3));

                    this.query_duration = res.took;
                    this.total_rows = res.total;
                    this.using_aggs = res.aggs;
                    this.timezone = res.timezone;

                    return {
                        rowData: res.rows,
                        rowCount: this.total_rows,
                    };
                }
                catch (ex) {
                    console.log(`get failed ${JSON.stringify(ex)}`);

                    this.queryError = ex.message || ex.reasonPhrase;

                    return {
                        rowData: [],
                        rowCount: 0,
                    };
                }
                finally {
                    this.query_in_progress = false;

                    // Check for any requests to refresh that were issued while a query was still in progress.
                    // Trigger a delayed refresh
                    if (this.query_refresh_pending) {
                        this.query_refresh_pending = false;
                        const thisref = this;
                        setTimeout(() => {
                            if (!thisref.query_in_progress)
                                thisref.Refresh();
                        }, 250);
                    }
                }
            },

            getColumnVisibility() {
                return this.columnVisibility;
            },
            getColumnDefs() {
                return this.columnDefs;
            },

            customerLookupFormatter(v) {
                // v.value is the cell's content
                // v.data is the row data
                return common.customerLookup[v.value] || v.value;
            },
            userLookupFormatter(v) {
                let value;
                if (typeof v.value === 'object' && Array.isArray(v.value))
                    value = v.value.map(a => common.userLookup[a] || a).join(', ');
                else
                    value = common.userLookup[v.value] || v.value;

                //console.log(`customerLookupFormatter(v:${JSON.stringify(v.value)}): ${value}`);
                return value;
            },
            phoneNumLookupFormatter(v) {
                // note: v.data is the full row record
                switch (v.data.timelinetype) {
                    case 'Chat':
                        return common.chatLookup[v.value] || v.value;

                    case 'EMail':
                        return v.value;

                    case 'SMS':
                    case 'Call':
                    default:
                        if (v.value && v.value.substring(0, 4) == 'USER')
                            return common.userLookup[v.value.substring(4)] || v.value;
                        else if (v.value && v.value.length == 36)
                            return common.userLookup[v.value] || v.value;
                        else if (v.value == '+10000000000')
                            return '(Loopback)';
                        else
                            return helps.formatPhoneNumber(v.value);
                }
            },

            customerLookupComparator(a, b) {
                const ca = common.customerLookup[a] || a;
                const cb = common.customerLookup[b] || b;
                return ca > cb ? 1 : ca < cb ? -1 : 0;
            },
            userLookupComparator(a, b) {
                const ca = common.userLookup[a] || a;
                const cb = common.userLookup[b] || b;
                return ca > cb ? 1 : ca < cb ? -1 : 0;
            },
            phoneNumLookupComparator(a, b) {
                const ca = this.phoneNumLookupFormatter(a);
                const cb = this.phoneNumLookupFormatter(b);
                return ca > cb ? 1 : ca < cb ? -1 : 0;
            },

            getUniqueColValues(colname) {
                return async (params) => {
                    const dataurl = `${common.buildRequest('Apps/ReportStudio/AdvancedReporting/GetUniqueColValues', this.settings.startDate, this.settings.endDate)}&Column=${colname}`;

                    console.log(`getUniqueColValues (${colname}) url: ` + dataurl.split('&').join('\n'));

                    const res = await utils.api.get(dataurl);
                    params.success(res.Values);
                };
            },

            notifyCustomMetrics(cols, dsl) {
                //alert('notify custom metrics ' + JSON.stringify(cols) + '  -  ' + JSON.stringify(dsl));
                this.customMetrics = dsl;

                const colDefs = [
                    ...this.columnDefs.map(c => ({ colId: c.colId, field: c.field, headerName: c.headerName, type: c.type })),
                    //{ "colId": "count", "field": "count", "filter": false, "headerName": "Count", "hide": true, type: "countColumn" },
                    ...cols
                ];
                this.gridApi.setGridOption('columnDefs', colDefs);
            },

            byField(a, b, field) {
                return a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0;
            },

            remove_count(field)
            {
                if (field.length > 6 && field.substring(field.length-6) == '_count')
                    return field.substring(0, field.length-6);
                else
                    return '__ignore_me__';
            },

            onGridReady(params) {
                this.gridApi = params.api;
                this.columnApi = params.columnApi;
            },
            stateUpdated(e) {
                if (!e.sources.some(a => ['columnOrder', 'columnPinning', '_columnSizing', 'sort'].some(b => a == b)))
                    return;

                const visiblecols = this.columnDefs.filter(a => a.colId && !a.hide).map(a => a.colId);

                const state = this.column_state || {};
                if (e.sources.includes('columnSizing'))
                    state.columnSizing = e.state.columnSizing.columnSizingModel.filter(a => visiblecols.includes(a.colId) && a.width != 150);

                if (e.sources.includes('columnOrder'))
                    state.columnOrder = e.state.columnOrder.orderedColIds.filter(a => visiblecols.includes(a));

                if (e.sources.includes('columnPinning')) {
                    state.leftPinned = e.state.columnPinning.leftColIds.filter(a => visiblecols.includes(a));
                    state.rightPinned = e.state.columnPinning.rightColIds.filter(a => visiblecols.includes(a));
                }
                this.column_state = state;

                //console.log('State sources: ' + JSON.stringify(e.sources, null, 3));
                //console.log('Saving state: ' + JSON.stringify(this.column_state));

                this.modelChanged();
            },
            onFirstDataRendered() {
                this.gridApi.autoSizeAllColumns();
                this.gridApi.refreshToolPanel();
            },

            dateFormatter(value) {
                return this.f_date(value, 'medium');
            },
            durationFormatter(value) {
                if (typeof value === 'number')
                    return this.f_number(value);
                else
                    value;
            },
            phonenumFormatter(value) {
                return this.f_phoneNumber(value);
            },

            applyDateRangeHandler(range) {
                console.log('applyDateRangeHandler');
                console.log(range.startDate);
                console.log(range.endDate);

                this.settings.startDate = range.startDate;
                this.settings.endDate = range.endDate;

                //if ('starttime' in this.filter_settings || 'datetime' in this.filter_settings) {
                //    const f = this.filter_settings.starttime || this.filter_settings.datetime;
                //    if (f.usage > 0) {
                //        f.filter = range.startDate;
                //        f.filterTo = range.endDate;
                //        const colId = this.filter_settings.starttime ? 'starttime' : 'datetime';
                //        this.EventBus.$emit(`${colId}_changed`, f);
                //    }
                //}

                utils.log(`Saving localStorage(${this.storageKey}-daterange, ${this.settings.startDate} - ${this.settings.endDate})`);
                this.System.LocalStorage(this.storageKey + '-daterange', `${this.settings.startDate} - ${this.settings.endDate}`);

                this.Refresh();
            },
            clearDateRangeHandler() {
            },
            clearSearch(){
                this.search = '';
                this.Refresh();
            },

            clearAllFilters() {
                this.EventBus.$emit('ClearAllReportFilters');
            },

            getRowClass(params) {
                if (this.treeData && params.data && params.data.child_count < 0)
                    return 'child-row';
            },
            isServerSideGroup(dataItem) {
                return dataItem.child_count > 0;
            },
            getServerSideGroupKey(dataItem){
                return dataItem.masterid;
            },

            autoSizeAllColumns() {
                this.gridApi.autoSizeAllColumns();
            },
            sizeColumnsToFit() {
                this.gridApi.sizeColumnsToFit();
            },
            setColumnsVisible(cols, value) {
                for (let i = 0; i < this.columnDefs.length; i++) {
                    const c = this.columnDefs[i];
                    if (cols.includes(c.colId))
                        c.hide = !value;                    
                }

                console.log(`setColumnsVisible(cols:${JSON.stringify(cols)}, value:${value})`);
                this.gridApi.setColumnsVisible(cols, value);

                this.modelChanged();

                // Only refresh when adding columns, otherwise, it's a waste
                if (value)
                    this.Refresh();

                if (this.ReadOnly) {
                    // In view mode, save the column visibility to local storage
                    const colstate = this.viewonly_settings.column_picker_list
                        .map(a => this.columnDefs.find(b => b.colId == a.ColId))
                        .map(c => ({ colId: c.colId, visible: !c.hide }));

                    this.System.LocalStorage(this.storageKey + '-columnsvisible', JSON.stringify(colstate));
                }
            },
            setColumnsDefaultHidden(colid, hidden) {
                if (hidden)
                    Vue.set(this.column_default_visibility, colid, 1);
                else
                    Vue.delete(this.column_default_visibility, colid);

                this.modelChanged();
            },

            reportNameChanged(newvalue) {
                //this.report_name = newvalue;
                if (this.setReportNameFunction)
                    this.setReportNameFunction(newvalue, this, helps, Vue);

                this.modelChanged();
            },
            reportDescChanged(newvalue) {
                //this.report_description = newvalue;
                if (this.setReportDescriptionFunction)
                    this.setReportDescriptionFunction(newvalue, this, helps, Vue);

                this.modelChanged();
            },

            showColumnSelector() {
                this.panelactive = 0;
                this.tool_visible = 0;
            },
            showDataFilters() {
                this.tool_visible = 1;
            },
            toggleDataFilters() {
                if (this.tool_visible == 1)
                    this.tool_visible = undefined;
                else
                    this.tool_visible = 1;
            },

            cycleDurationFormats() {
                this.durationFormatIdx = (this.durationFormatIdx + 1) % 3;
                this.gridApi.refreshCells();
            },
        },
        watch: {
            treeData: function (newvalue) {
                this.gridApi.setColumnsVisible(['starttime'], !newvalue);
                this.autoGroupColumnDef = newvalue ?
                    this.groupColDef_byMasterID :
                    this.groupColDef_Normal;
            },
            //source_selected: function (newv) {
            //    this.loadColumns(newv, true);
            //},
            search: function (newvalue) {
                const self = this;
                _.debounce(function () { self.Refresh(); }, 250)();

            },
            TimeZone: function (newvalue) {
                if (newvalue)
                    this.Refresh();
            },
        }
    };
</script>

<style>
    .child-row {
        background-color: cornsilk !important;
    }
    .history-panel-width {
        min-width: 750px;
        max-width: 750px;
    }
    .right-aligned-cell {
        text-align: right;
        flex-direction: column-reverse;
    }
    .icon-spinner {
        animation: spin-animation 1.5s infinite;
        display: inline-block;
    }
    .standard-input-field {
        background-color: white;
    }
    .v-expansion-panel-header {
        background-color: var(--v-extraLightGrey-base);
    }
    .v-expansion-panel-content {
        background-color: var(--v-extraLightGrey-base);
    }
    .v-expansion-panel-header__icon{
        margin-left: 0 !important;
    }
    
    @keyframes spin-animation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(359deg);
        }
    }
    
</style>

<style scoped>
    .expandIconSpoof {
        transition: transform .3s ease-in-out !important; /* spoofing expansion pannel action icon. Needed to allow positioning of "Clear All" button */
    }
    .expandIconSpoof.rotate {
        transform: rotate(-180deg); /* spoofing expansion pannel action icon. Needed to allow positioning of "Clear All" button */
    }
    .consistentText {
        color: var(--v-black-base) !important; 
        opacity: 0.8 !important; 
        font-size: 14px !important; 
        text-rendering: optimizelegibility !important; 
        letter-spacing:0.1 !important;
    }
</style>
